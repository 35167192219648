import { css } from '@emotion/core';
import { Typography } from '@material-ui/core';
import firebase from 'firebase/app';
import { sortBy } from 'lodash';
import React from 'react';
import { HidableBranding as Branding } from '../components/Branding';
import { onlyOnClientSide } from '../components/ClientSidePage';
import { Event } from '../components/Event';
import { ProductHuntBanner } from '../components/ProductHuntBanner';
import { SEO } from '../components/SEO';
import Layout, { Wrapper } from '../layouts/Layout';
import { useMappedLoadingValue } from '../services/db';
import { useEvents } from '../services/events';
import styled from '../styled';
import { IEvent } from '../types/Event';
import { Doc } from '../types/Document';
import { CommunityFooter } from '../components/CommunityFooter';

const Text = styled('p')`
  color: ${(p) => p.theme.palette.grey['A200']};
  max-width: 500px;
  margin: 0 auto;
  font-size: ${(p) => p.theme.typography.body1.fontSize};
  line-height: ${(p) => p.theme.typography.body1.lineHeight};

  a {
    border-bottom: 1px solid ${(p) => p.theme.palette.grey['A200']};
  }
`;

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  grid-row-gap: ${(p) => p.theme.spacing(8)}px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }

  & > div {
    margin-bottom: 12px;
  }
`;

const PostContainer = styled('div')`
  ${({ theme }) => css`
    max-width: 1200px;
    padding: ${theme.spacing(2)}px;
    margin: ${theme.spacing(4)}px auto;

    @media (max-width: 800px) {
      padding: ${theme.spacing(1)}px;
      margin: ${theme.spacing(2)}px;
    }
  `};
`;

const EventList = ({ events }: { events: void | Doc<IEvent>[] }) => {
  if (!events) {
    return null;
  }

  const pastEvents = events.filter(
    (e) => firebase.firestore.Timestamp.now() > e.data.date
  );
  const upcomingEvents = events
    .filter((e) => firebase.firestore.Timestamp.now() < e.data.date)
    .sort((a, b) => a.data.date.toMillis() - b.data.date.toMillis());

  return (
    <div>
      <Grid>
        {upcomingEvents.map((e) => (
          <Event key={e.id} {...e} />
        ))}
      </Grid>
      <br />
      <br />
      <div
        style={{
          margin: '36px auto',
          textAlign: 'center'
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Past Events (9 Most Recent)
        </Typography>
      </div>
      <Grid>
        {pastEvents.slice(0, 9).map((e) => (
          <Event key={e.id} {...e} />
        ))}
      </Grid>
    </div>
  );
};

const EventsWrapper = onlyOnClientSide(() => {
  const [events] = useMappedLoadingValue(useEvents(), (es) =>
    sortBy(es, (e) => -e.data.date.toMillis())
  );

  return <EventList events={events} />;
});

const IndexPage = () => {
  return (
    <Layout>
      <ProductHuntBanner />
      <SEO
        title="Blogging for Devs Events"
        siteUrl="https://bloggingfordevs.com"
        description="Workshops, livestreams, and AMA sessions with your favorite bloggers and creators in tech."
        socialSharingImage="/images/bfd-events-preview.png"
        pathname="events/"
      />
      <Wrapper>
        <div
          style={{
            margin: '0 auto',
            textAlign: 'center'
          }}
        >
          <Branding to="/">Blogging for Devs</Branding>
          <Typography variant="h4" component="h1" gutterBottom>
            Upcoming Events
          </Typography>
          <Text>
            Connect with and learn from other devs who blog at our meetups,
            workshops, and expert livestreams.
          </Text>
        </div>
      </Wrapper>
      <PostContainer>
        <EventsWrapper />
      </PostContainer>
      <CommunityFooter />
    </Layout>
  );
};

export default IndexPage;
